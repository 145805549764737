.loginPage {
    /* margin: 0 auto; */
    /* margin: 2em auto; */
    display: flex;
    /* width: fit-content; */
    height: fit-content;
    align-self:baseline;
    /* justify-self: center; */
    justify-content: center;
    align-content: baseline;
  }
  
  .auth-app-page {
    /* margin: 0 auto; */
    /* margin: 2em auto; */
    display: flex;
    width: fit-content;
    height: fit-content;
    align-self: flex-start;
    /* justify-self: center; */
    justify-content: center;
    align-content: flex-start;
    align-items: flex-end;
  }
  
  /* React Default Style */
  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    /* background-color: #282c34; */
    /* min-height: calc(100vh - 50px); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .tab {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(max-content, 4);
    border: 1px solid rgba(11, 22, 234, 0.336);
  }
  

  .ant-switch-inner-unchecked {
  }